
import {computed, defineComponent} from "vue";
import {useRoute} from 'vue-router'



export default defineComponent({
  name: "accounting",
  components: {
  },
  methods: {

  },
  setup() {
    const route=useRoute();
    const path = computed(() =>route.name);

    return {
      open,
      path,

    }
  },
});
